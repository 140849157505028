import React, { Fragment } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'

import Layout from '../../components/Layout'
import { StaticImage } from 'gatsby-plugin-image'

import AzureImage from '../../resources/images/wizard_form/azure.png'

import azure_header from '../../resources/images/azure_page/hero_azure.svg'

import Logos from '../../components/partials/Logos'
import Spacing from '../../components/partials/Spacing'

const azure = () => {
    const data = useStaticQuery(graphql`
        query CloudAzureSEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "Cloud_Azure" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Fragment>
            <Layout>
                <HeaderSEO pageSEO={pageSEO} />
                <StaticImage
                    className="home-fixed-top"
                    src="../../resources/images/homepage/hero_lens.svg"
                    placeholder="blurred"
                    alt="Hero Lenses"
                />
                <section className="mobile-margin-top">
                    <div className="container-1 hero-margin">
                        <div className="d-flex flex-wrap align-items-center">
                            <div className="col-lg-4 col-xl-5  justify-content-center align-self-center col-12 mobile-padding-left ">
                                <p className="title breadcrumb-text">
                                    <a href="/cloud/" className="title_text">
                                        CLOUD
                                    </a>{' '}
                                    /<span className="title_text font-weight-bold">AZURE</span>
                                </p>
                                <div className="mt-5">
                                    <a
                                        id="clouds-azure-hero-button-getTrial"
                                        href="/downloads/lenses-enterprise/?s=azure"
                                    >
                                        <img className="height-80" src={AzureImage} alt="AZURE" />
                                    </a>

                                    <h1>
                                        DataOps portal for Microsoft Azure Apache Kafka (HDInsight)
                                        <br />
                                    </h1>
                                    <div className="pr-5">
                                        <h2 className="hero-description-dark pr-5 pt-1">
                                            Success as a real-time business with DataOps practices
                                            on Azure HDInsight. Enterprise ready monitoring,
                                            security & data intensity
                                        </h2>
                                    </div>

                                    <div className="roll-button mt-3">
                                        <a
                                            id="clouds-azure-hero-button-getTrial-2"
                                            className="text-white bg-red"
                                            href="/downloads/lenses-enterprise/?s=azure"
                                        >
                                            <span data-hover="Try for free">Try for free</span>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-8 col-xl-7 pt-0 col-12  px-0">
                                <img
                                    className="img-fluid mx-auto d-block w-100"
                                    src={azure_header}
                                    alt="homepage hero"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <Spacing />
                {/* <!-- Customers logos --> */}
                <section className="mt-5">
                    <Logos width={75} />
                </section>
                <Spacing />

                {/* <!-- First Image content --> */}
                <section>
                    <div className="container-1">
                        <div className="flex-wrap d-flex align-items-center">
                            <div className="col-md-6 col-12 mobile-padding-top">
                                <h2 className="h1-like">Real-time with Azure HDInsight</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        HDInsight is a fully-managed Apache Kafka infrastructure on
                                        Azure. Now you must apply enterprise-ready monitoring,
                                        security & governance to operate your real-time applications
                                        with confidence
                                    </p>
                                    <p>
                                        DataOps best practises allows the data platform team to
                                        enable everyone from developers to analysts to rapidly
                                        deliver new digital data experiences with confidence on
                                        Azure HDInsight with tight-integration with Azure AD,
                                        Kubernetes and more.
                                    </p>
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5">
                                <StaticImage
                                    className="img-fluid"
                                    src="../../resources/images/aws_page/unleasedDataopsforAWS.svg"
                                    placeholder="tracedSVG"
                                    alt="Lenses - data monitoring"
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Testimonial --> */}

                <Spacing />
                <section>
                    <div className="container-1">
                        <div className="d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-6 col-sm-7  testimonial-open-source py-3 ">
                                <StaticImage
                                    className="testimonial-open-source-company-image"
                                    src="../../resources/images/clients/vortexa/vortexa-with-color.png"
                                    placeholder="tracedSVG"
                                    alt="Vortexa"
                                />
                                <p className="testimonial-text">
                                    From the <strong>CEO</strong> to the Front-end
                                    <strong> engineer</strong> all{' '}
                                    <strong> fully understand</strong> how Kafka and our real-time
                                    AI applications are transforming the commodity market.{' '}
                                    <a href="/">Lenses.io</a> has been
                                    <strong> invaluable</strong> at providing complete transparency
                                    and control over
                                    <strong> real-time</strong> infrastructure,data and apps.
                                </p>
                                <a className="primary-text" href="/customers/vortexa/">
                                    Read More <i className="red-pointer-right"></i>
                                </a>
                                <StaticImage
                                    className="arrow-right-image"
                                    src="../../resources/images/homepage/testimonials/triangle.svg"
                                    placeholder="tracedSVG"
                                    alt="triangle"
                                />
                            </div>

                            <div className="offset-2 offset-sm-1 col-3 flex-column flex-sm-row align-items-center d-flex mobile-padding-reset">
                                <StaticImage
                                    imgClassName="quote-image"
                                    src="../../resources/images/Homepage/testimonials/maksym.png"
                                    placeholder="tracedSVG"
                                    alt="Maksym"
                                />
                                <div className="ml-3 d-flex w-100 flex-column mobile-margin-reset">
                                    <div>
                                        <p className="light-blue fw-400 mb-0"> Maksym Schipka</p>
                                    </div>
                                    <div>CTO of Vortexa</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Spacing />
                <section>
                    <div className="container-1">
                        <div className="flex-wrap mt-4 d-flex align-items-center">
                            <div className="col-md-6">
                                <h2 className="h1-like">Enjoy unparalled observability</h2>
                                <div className="pr-3">
                                    <p className="pr-5 mobile-margin-top-half">
                                        No more black box. Keep track of the health of your
                                        infrastructure, data and real-time applications including
                                        Kafka Connect, Kubernetes and Schema Registry. Fully
                                        supports Azure AD for SSO.
                                    </p>
                                    <p>
                                        Monitor the performance of flows and microservices including
                                        consumer lag & partition health. Stay proactive with alerts
                                        intgrated with your existing tools.
                                    </p>
                                </div>
                                <a
                                    id="monitor-dark-learnmore"
                                    className="primary-text link-text-azure"
                                    href="/usecases/kafka-monitoring/"
                                >
                                    {' '}
                                    More about monitoring <i className="red-pointer-right"></i>
                                </a>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5 mobile-margin-top-half">
                                <div className="pt-3">
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/confidenceThroughVisibility.svg"
                                        placeholder="tracedSVG"
                                        alt="Lenses - data monitoring"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Third Image --> */}
                <section>
                    <div className="container-1 pt-5">
                        <div className="d-flex flex-wrap mt-4 align-items-center">
                            <div className="col-md-5 ">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/migrateWithConfidence.svg"
                                        placeholder="tracedSVG"
                                        alt="Lenses - data monitoring"
                                    />
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6 mobile-margin-top-half ">
                                <h2 className="h1-like">Migrate with confidence</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Reduce risk, increase deployment consistency and velocity by
                                        managing your application landscape via configuration files
                                        across different Kafka deployments. Integrate into your
                                        CI/CD via GitOps and YAMLs!{' '}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Fourth Image --> */}
                <section>
                    <div className="container-1 pt-5">
                        <div className="flex-wrap mt-4 d-flex align-items-center">
                            <div className="col-md-6 pt-5">
                                <h2 className="h1-like">Data access</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Accelerate debugging by putting streaming data into the
                                        hands of those that need it most. Use SQL to search, filter,
                                        join, aggregate & insert data on the wire.{' '}
                                    </p>
                                    <p>
                                        All whilst protected with authentication, audits & redaction
                                        policies. Integrate data via popular libraries such as in
                                        Python & Go.
                                    </p>
                                    <a
                                        id="explore--access-learnmore"
                                        className="primary-text"
                                        href="/usecases/apache-kafka-data-observability/"
                                    >
                                        More about Data Access <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5 mobile-margin-top-half">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/azure_page/dataAccess.svg"
                                        placeholder="tracedSVG"
                                        alt="Lenses - data access"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Fifth Image --> */}
                <section>
                    <div className="container-1 pt-5">
                        <div className="flex-wrap mt-4 d-flex align-items-center">
                            <div className="col-md-5">
                                <div className="pt-5">
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/selfServiceWithGovernance.svg"
                                        placeholder="tracedSVG"
                                        alt="Lenses - data monitoring"
                                    />
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6 mobile-margin-top-half">
                                <h2 className="h1-like">Self service with governance</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Increase productivity - empower developers to self serve.
                                        Manage Topics, ACLs, Quotas, Schemas & flows with full
                                        governance protected by role-based security via namespaces
                                        and auditing.{' '}
                                    </p>
                                </div>
                                <a
                                    id="aws-carousel-monitor-learnmore"
                                    className="primary-text"
                                    href="/blog/2019/10/secure-self-service-and-governance-portal-for-kafka/"
                                >
                                    Read blog on self-service security{' '}
                                    <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Sixth Image --> */}
                <section>
                    <div className="container-1 pt-5">
                        <div className="flex-wrap mt-4 d-flex align-items-center">
                            <div className="col-md-6">
                                <h2 className="h1-like">Power flows to production</h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Socialize your real-time apps and data, and enable via
                                        simple SQL even the not technical skilled people to join
                                        your real-time data revolution and build more reliable
                                        streaming flows. Create streaming applications or data
                                        integrations to popular data stores. Deploy & scale on any
                                        Kubernetes environment such as Azure ACI or Kafka Connect.
                                    </p>
                                    <a
                                        id="build-power-learnmore"
                                        className=" primary-text"
                                        href="/usecases/kafka-streams/"
                                    >
                                        More about build use case{' '}
                                        <i className="red-pointer-right"></i>
                                    </a>
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-5 mobile-margin-top-half">
                                <div>
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/powerFlowsToProduction.svg"
                                        placeholder="tracedSVG"
                                        alt="Lenses - data monitoring"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Seventh Image --> */}
                <section>
                    <div className="container-1 pt-5">
                        <div className="flex-wrap mt-4 d-flex align-items-center">
                            <div className="col-md-5">
                                <div className="pt-2">
                                    <StaticImage
                                        className="img-fluid mx-auto d-block"
                                        src="../../resources/images/aws_page/integrationsThatFitLikeAGlove.svg"
                                        placeholder="tracedSVG"
                                        alt="Lenses - data monitoring"
                                    />
                                </div>
                            </div>
                            <div className="offset-md-1"></div>
                            <div className="col-md-6">
                                <h2 className="h1-like mobile-margin-top">
                                    Integrations that fit like a glove
                                </h2>
                                <div className="pr-3 mobile-margin-top-half">
                                    <p className="pr-5">
                                        Accelerate your real-time data and application portal
                                        capabilities by months and increase the success and adoption
                                        of you streaming data platform to production with enterprise
                                        and regulation rediness.
                                    </p>
                                    <p>
                                        Manage roles & identities via LDAP, Kerberos or Azure AD
                                        (SSO). Send audits to popular SIEMs such as Splunk or
                                        trigger alerts with custom routing to tools such as Slack &
                                        Pagerduty.
                                    </p>
                                </div>
                                <a
                                    id="integrations-glove-learnmore"
                                    className="primary-text"
                                    href="/architecture-and-integrations/"
                                >
                                    More about integrations <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-red p-5" style={{ marginTop: '70px' }}>
                    <div className="container ">
                        <div className="d-flex flex-wrap">
                            <div className="col-md-12 col-12 text-center roll-button">
                                <p className="text-white paragraph-title pb-3 lh-2-1">
                                    Auto-Discovery & Seamless integration
                                </p>
                                <a
                                    className="bg-white primary-text "
                                    href="https://azuremarketplace.microsoft.com/en-us/marketplace/apps/lensesioltd.lenses-io"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <span data-hover="Try via Azure marketplace!">
                                        Try via Azure marketplace!
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="se-section pt-5 pb-5">
                    <div className="container-1 pt-5">
                        <div className="d-flex flex-wrap pb-5">
                            <div className="col-md-12 text-center">
                                <h2 className="paragraph-title">
                                    Azure Marketplace - Frequently Asked Questions
                                </h2>
                                <p className="mt-4">
                                    Lenses.io is fully integrated with Microsoft Azure and can be
                                    installed as an <i>edge node</i>
                                    on your HDInsight Apache Kafka cluster (or isolated with an
                                    Azure Resource Manager template).
                                </p>
                            </div>
                        </div>

                        <div className="d-flex flex-wrap">
                            <div className="col-md-8 offset-md-2">
                                {/* <!-- FAQ-1 --> */}
                                <div className="card-group mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <a
                                                    data-toggle="collapse"
                                                    href="#faq1"
                                                    className="faq-link"
                                                >
                                                    Where Lenses.io will be deployed?
                                                </a>
                                            </div>
                                        </div>
                                        <div id="faq1" className="collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lenses.io will be deployed as an{' '}
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href="https://docs.microsoft.com/en-us/azure/hdinsight/hdinsight-hadoop-provision-linux-clusters#install-hdinsight-applications-on-clusters"
                                                    >
                                                        edge node
                                                    </a>
                                                    on your Azure HDInsight cluster.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- FAQ-2 --> */}
                                <div className="card-group mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <a
                                                    data-toggle="collapse"
                                                    href="#faq2"
                                                    className="faq-link"
                                                >
                                                    Can I enable Zookeeper JMX port for Azure
                                                    HDInsight?
                                                </a>
                                            </div>
                                        </div>
                                        <div id="faq2" className="collapse">
                                            <div className="card-body">
                                                <p>
                                                    On Azure HDInsight, Zookeeper nodes have JMX
                                                    ports disabled and you need to enable them by
                                                    yourslef as our
                                                    <a href="https://docs.lenses.io/4.3/installation/cloud/azure/">
                                                        documentation
                                                    </a>
                                                    describes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- FAQ-3 --> */}
                                <div className="card-group mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <a
                                                    data-toggle="collapse"
                                                    href="#faq3"
                                                    className="faq-link"
                                                >
                                                    How can I access Lenses.io edge node on Azure
                                                    HDInsight cluster?
                                                </a>
                                            </div>
                                        </div>
                                        <div id="faq3" className="collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lenses.io edge node is not accessible directly
                                                    as it is part of the Azure HDInsight cluster.
                                                    First, you should access your master node and
                                                    then from this machine you can access through
                                                    SSH Lenses edge node.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <!-- FAQ-4 --> */}
                                <div className="card-group mb-3">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-title">
                                                <a
                                                    data-toggle="collapse"
                                                    href="#faq4"
                                                    className="faq-link"
                                                >
                                                    Where is the Lenses.io configuration located on
                                                    Azure HDInsight edge node?
                                                </a>
                                            </div>
                                        </div>
                                        <div id="faq4" className="collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lenses.io configuration is located under the
                                                    path <code>/opt/lenses</code>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-red p-5">
                    <div className="container ">
                        <div className="d-flex flex-wrap">
                            <div className="col-md-12 col-12 text-center roll-button">
                                <p className="text-white paragraph-title pb-3 lh-2-1">
                                    Talk with a Lenses.io expert at your convenience
                                </p>
                                <a className="bg-white primary-text " href="/contact-us/?s=azure">
                                    <span data-hover="Let's talk #DataOps">
                                        Let&apos;s talk #DataOps
                                    </span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>

                <section className=" pt-5 pb-5">
                    <div className="container">
                        <div className="d-flex flex-wrap">
                            <div className="col-sm-12 text-center pt-5">
                                <h2 className="paragraph-title mb-5 ">
                                    Deploy Lenses where you want
                                </h2>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap">
                            <div className="col-sm-12 col-md-3 offset-md-3 text-center">
                                <StaticImage
                                    className="w-40"
                                    src="../../resources/images/wizard_form/on-prem.png"
                                    placeholder="tracedSVG"
                                    alt="Premises"
                                />
                                <h2 className="paragraph-title pt-3">On prem</h2>
                                <p className=" text-center">
                                    Install the platform in your <br />
                                    physical data center.
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-3 text-center">
                                <StaticImage
                                    className="w-40"
                                    src="../../resources/images/wizard_form/on-cloud.png"
                                    placeholder="tracedSVG"
                                    alt="Cloud"
                                />
                                <h2 className="paragraph-title  pt-3">On cloud</h2>
                                <p className=" text-center">
                                    Deploy the Lenses in your <br />
                                    virtual private cloud.
                                </p>
                            </div>
                            <div className="col-sm-12 col-md-12 pt-5 pb-5 text-center">
                                <a className="primary-text" href="/product/deployment/">
                                    Deployment options <i className="red-pointer-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </Fragment>
    )
}

export default azure
